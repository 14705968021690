import throttle from 'utils/throttle'
import { BaseComponent } from 'utils/base-component'

import { LeafletConfig } from './leaflet-config'
import { LeafletRepository } from './repository'
import './leaflet.scss'
import './static/placeholder-icon.svg'
import './static/placeholder.jpg'

export interface MapConfig {
  filename: string
  lat: number
  lng: number
  topoUrl: string
  bbox?: [number, number, number, number]
  screenshotMode?: boolean
}

type Config = {
  mapConfigList: MapConfig[]
}

export class Map extends BaseComponent<Config> {
  scrollListener?: () => void
  clickListener?: () => void

  static cssClass = 'leaflet'

  init() {
    this.toggleModifier('loading')

    if (window.matchMedia('(min-width: 768px)').matches) {
      this.scrollListener = throttle(() => {
        this.loadMapIfInViewport()
      }, 500)

      window.addEventListener('scroll', this.scrollListener)
      this.loadMapIfInViewport()
    } else {
      this.clickListener = this.loadMapOnClick.bind(this)
      this.root.addEventListener('click', this.clickListener)
    }
  }

  loadMapOnClick() {
    if (this.clickListener) {
      this.root.removeEventListener('click', this.clickListener)
      this.clickListener = undefined
    }

    this.chunk()
  }

  loadMapIfInViewport() {
    if (this.inViewport()) {
      if (this.scrollListener) {
        window.removeEventListener('scroll', this.scrollListener)
        this.scrollListener = undefined
      }

      this.chunk()
    }
  }

  private chunk() {
    this.toggleModifier('loading')

    const { mapId } = this.root.dataset
    if (mapId === undefined) return

    import(/* webpackChunkName: 'leaflet' */ './leaflet').then((module) => {
      const repository = new LeafletRepository()

      const mapConfig: MapConfig = this.config.mapConfigList[Number(mapId)]
      const leafletConfig: LeafletConfig = {
        repository,

        locationName: mapConfig.filename,
        LatLng: [mapConfig.lat, mapConfig.lng],
        bbox: mapConfig.bbox,

        urlTemplates: {
          topo: mapConfig.topoUrl
        },

        screenshotMode: mapConfig.screenshotMode
      }

      // eslint-disable-next-line no-new
      new module.Leaflet(this.root, leafletConfig)

      this.toggleModifier('loading')
      this.element('placeholder')?.element.remove()
    })
  }

  private inViewport() {
    const bounding = this.root.getBoundingClientRect()
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight

    return (
      (bounding.top >= 0 && bounding.top <= viewportHeight) ||
      (bounding.bottom >= 0 && bounding.top <= viewportHeight)
    )
  }
}
