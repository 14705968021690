const format = v => (String(v).length === 1 ? `0${v}` : v);

const insert = (el, { h, m, s, sign, delta }) => {
  const hours = el.querySelector('.hours');
  if (hours) hours.textContent = sign + h;
  const minutes = el.querySelector('.minutes');
  if (minutes) minutes.textContent = m;
  const seconds = el.querySelector('.seconds');
  if (seconds) seconds.textContent = s;
  const hoursPart = el.querySelector('.hours-part');
  // Hide the hour part when less than an hour remains to wait
  if (h || sign) {
    if (hoursPart.style.display === 'none') {
      hoursPart.style.display = 'inline';
    }
  } else if (hoursPart.style.display !== 'none') {
    hoursPart.style.display = 'none';
  }
  const timeEstimate = el.querySelector('.time-estimate');
  if (delta < 0) {
    if (!timeEstimate.classList.contains('countdown-hidden')) {
      timeEstimate.classList.add('countdown-hidden');
    }
  } else if (timeEstimate.classList.contains('countdown-hidden')) {
    timeEstimate.classList.remove('countdown-hidden');
  }

  const updateImminent = el.querySelector('.update-imminent');
  if (delta >= 0 || delta < -1800) {
    if (!updateImminent.classList.contains('countdown-hidden')) {
      updateImminent.classList.add('countdown-hidden');
    }
  } else if (updateImminent.classList.contains('countdown-hidden')) {
    // allow up to 30 minutes update delay, show as "update imminent"
    updateImminent.classList.remove('countdown-hidden');
  }
};

class Countdown {
  constructor(el, updateTs, tc) {
    this.el = el;
    this.updateTs = updateTs;
    this.tc = tc;
    this.timer = null;
  }

  start() {
    this.update();
    this.timer = setTimeout(() => {
      this.start();
    }, 1000);

    return this;
  }

  stop() {
    clearTimeout(this.timer);

    return this;
  }

  update() {
    const delta = this.updateTs - Math.round(Date.now() / 1000 + this.tc);
    const h = Math.floor(Math.abs(delta / 3600));
    const m = Math.floor(Math.abs(delta % 3600) / 60);
    const s = format(Math.abs(delta) % 60);
    const sign = delta >= 0 ? '' : '-';
    insert(this.el, { h, m, s, sign, delta });

    return this;
  }
}

export default function () {
  const { FCGON } = window;

  if (!FCGON) return [];

  const { forecast_update_ts: updateTs, server_time: serverTime } = FCGON;

  if (!updateTs || !serverTime) return [];

  const timeCorrection = serverTime - Date.now() / 1000;
  const els = document.querySelectorAll('[data-countdown]');

  return [...els].map(el =>
    new Countdown(el, updateTs, timeCorrection).start()
  );
}
